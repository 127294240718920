export function navigationTrigger (selector) {
  const triggers = document.querySelectorAll(selector);
  triggers.forEach(function (trigger) {
    const wrapper = trigger.closest('[data-navigation-wrapper]');
    const navigation = wrapper.querySelector('.navigation');
    trigger.addEventListener('click', function (e) {
      trigger.classList.toggle('is-active');
      navigation.classList.toggle('navigation--expanded');
      e.preventDefault();
    });
  });
}
